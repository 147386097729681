import React from 'react'
import "./BetterChoice.scss";

import betterScreen from "../../assets/images/better-screen.webp";
import ExploreNow from '../exploreNow/ExploreNow';




const BetterChoice = ({setBookingFlag}) => {
  return (
    <div className='better-choice-section'>
      <div className='better-choice-container'>
        <div className='header non-mobile'>Manage your photoshoots <br />
for your business from one place</div>
<div className='header only-mobile'>Manage your photoshoots 
for <br />your business from one place</div>
        <div className='text non-mobile'>Staying well-informed and maintaining constant control over your photography session</div>
        <div className='text only-mobile'>Staying well-informed and maintaining <br />constant control over your photography session</div>
        <div className='middle-section'>
            <img src={betterScreen} alt='screen-img' />
        </div>
        <div className='last-section'>
            <ExploreNow setBookingFlag={setBookingFlag} />
        </div>
        <div className='last-text non-mobile'>Know all the features about FlixStudio Software <a>Click here</a></div>
      </div>
    </div>
  )
}

export default BetterChoice
