import React from "react";
import "./Hqi.scss";
import img from "../../assets/images/hqi-img.webp";
import icon_1 from "../../assets/images/hqi-icon.webp";
import icon_2 from "../../assets/images/approved-icon.webp";

const Hqi = () => {
  return (
    <div className="hqi-section">
      <div className="hqi-container">
       <div className="top-section">
       <div className="heading">
          <span className="yellow-span">HQI - High Quality Image</span>{" "}
          Assurance by FlixStudio
          <span className="bold-span"> A 60 Points Quality Check System</span>
        </div>
        <div className="sub-heading">
          FlixStudio guarantees unparalleled image quality through a rigorous
          60-point quality check, ensuring your images are flawless every time.
          Choose us for uncompromising excellence in image perfection.
        </div>
       </div>
       <div className="bottom-section">
        <img src={icon_1} alt="hqi-icon" className="hqi-icon" />
       <div className="img">
            <img src={img} alt="img" />
        </div>
        <img src={icon_2} alt="approved-icon" className="approved-icon" />
        <div className="tic-container">
            {/* <img src={icon_3} alt="tic"  /> */}
            <img src="https://gati-public.s3.ap-south-1.amazonaws.com/websiteAssets/assetsForLandingPage/assets/images/icon5.svg" alt="tic"  />
            <div className="text">55 pts and <br />
above is HQI </div>
        </div>
       </div>
      </div>
    </div>
  );
};

export default Hqi;
