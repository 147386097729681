import React from 'react'
import "./LookCreator.scss";
import coma from "../../assets/images/coma-1.webp";
import video from "../../assets/videos/Green.mp4"
import ReactPlayer from 'react-player';


const LookCreator = () => {
  return (
    <div className='lookcreator-section'>
        <div className='lookcreator-container'>
        <div className="player-wrapper">
              <ReactPlayer
                url={video}
                loop={true}
                width={"100%"}
                height={"100%"}
                playing={true}
                muted={true}
                controls={false}
                className="video"
              />
            </div>
            <div className='right-side'>
                <div className='content'>
                    <div className='coma'>
                        <img src={coma} alt='coma' />
                    </div>
                    <div className='text'>
                        <div className='heading'>Look Creator</div>
                        <div className="sub-heading">100% control on styling and <br />pairing of your product.</div>
                    </div>
                </div>
            </div>
        </div>
      
    </div>
  )
}

export default LookCreator
