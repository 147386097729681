import React from "react";
import "./HowToSucceed.scss";
import { Col, Row } from "antd";
import vector_1 from "../../assets/images/vector_1.svg";
import vector_2 from "../../assets/images/vector_2.svg";
import vector_3 from "../../assets/images/vector_3.svg";
import vector_4 from "../../assets/images/vector_4.svg";
import vector_5 from "../../assets/images/vector_5.svg";
import vector_6 from "../../assets/images/vector_6.svg";

const data = [
  {
    id: 1,
    img: vector_1,
    title: "Share with your inner circle",
    text: 
     "Tell friends and family how FlixStudio has helped your business and how they can benefit from them."
    ,
  },
  {
    id: 2,
    img: vector_2,
    title: "Write a blog post",
    text: ` Do you write about productivity or automation? Describe your experience
    with FlixStudio on your blog.`,
  },
  {
    id: 3,
    img: vector_3,
    title: "Spread the word on social",
    text: ` Share about your favourite product and let your followers know about
    your love for FlixStudio.`,
  },
  {
    id: 4,
    img: vector_4,
    title: "Publish educational content",
    text: ` Get creative. Record tutorials, run webinars or even create courses on
    FlixStudio.`,
  },
  {
    id: 5,
    img: vector_5,
    title: "Run digital campaigns",
    text: ` Promote FlixStudio on your website through banner ads and other content
    using your affiliate link.`,
  },
  {
    id: 6,
    img: vector_6,
    title: "Get your clients onboard",
    text: ` Are you an agency or freelancer? Recommend FlixStudio to your clients to
    earn more on top of your services.`,
  },
];

const HowToSucceed = () => {
  return (
    <div className="success-section">
      <div className="success-container">
        <div className="title-heading">
          How to succeed as a FlixStudio Affiliate
        </div>
        <div className="title-text">
          Become our affiliate partner and earn recurring commission on every
          sale.
        </div>
        <Row gutter={[112,64]} className="items">
          {data.map((item, index) => (
            <Col span={8} key={index}>
              <div className="item">
                <img src={item.img} alt={item.id} />
                <div className="subheading">{item.title}</div>
                <div className="subtext">{item.text}</div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default HowToSucceed;
