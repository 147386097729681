import React from 'react'
import "./FullControl.scss";
import { CustomArrowButton as Button } from '../../CommonComponents/button/Button';

const FullControl = ({setBookingFlag }) => {
  return (
    <div className='full-control-section'>
        <div className='full-control-container'>
            <div className='heading'>
            You get full control over styling 
            </div>
            <div className='text non-mobile'>
            AI based Styling & pairing recommendation - DIY Look creator - Team of expert fashion stylists - <br />all focus on achieving the desired look and aesthetic for the clothing being showcased.
            </div>
            <div className='text only-mobile'>
            AI based Styling & pairing recommendation - DIY <br />Look creator - Team of expert fashion stylists - all <br />focus on achieving the desired look and aesthetic <br />for the clothing being showcased.
            </div>
            <Button className="elevate-btn"  title="Get in Touch" setBookingFlag={setBookingFlag}/>
        </div>
      
    </div>
  )
}

export default FullControl
