import React from "react";
import "./SelectingModel.scss";
import video_top from "../../assets/images/video-top.webp";
import video from "../../assets/videos/Booking details.mp4";
import ReactPlayer from "react-player";

const SelectingModel = () => {
  return (
    <div className="selecting-model-section">
      <div className="selecting-model-container">
        <div className="heading">
          Selecting model for your photoshoot has never been this easier and
          hassle free !!
        </div>
        <div className="sub-heading">
          How we make it so easier for your to choose models
        </div>
        {/* <div className="img">
          <img src={img} alt="img" />
        </div> */}
        <div className="video-container">
            <div className="dot-container">
             <img src={video_top} alt="video-top" />
            </div>
            <div className="player-wrapper">
              <ReactPlayer
                url={video}
                loop={true}
                width={"100%"}
                height={"100%"}
                playing={true}
                muted={true}
                controls={false}
                className="video"
              />
            </div>
          </div>
        <div className="items">
          <div className="item">
            <span>Models of multiple</span> shape size bodytype ethnicity
            demographics etc
          </div>
          <div className="item">
            <span>Partner</span> with world class modeling agencies
          </div>
        </div>
        <div className="items">
          <div className="item">
            <span>One stop shop for exploring world class model</span> ,
            eliminating the effort of talking to multiple modeling agency
          </div>
          <div className="item">
            <span>AI based - Model profile Screening system</span> to filter out
            best models available in the market.
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectingModel;
