
import React, { useState } from 'react'
import { CustomHeader } from '../header/Header'
import { CustomFooter } from '../Footer/Footer'
import { Layout } from 'antd'
import Routers from '../../routers/Routers'
import DynamicModal from '../DynamicModal/DynamicModal'
import BookingForm from '../BookingForm/BookingForm'

export const CustomLayout = () => {
  const [bookingFlag, setBookingFlag] = useState(false);
  return (
    <Layout>
    <CustomHeader setBookingFlag={setBookingFlag} />

        <Routers setBookingFlag={setBookingFlag} />
  
    <CustomFooter setBookingFlag={setBookingFlag} />
    {bookingFlag && (
        <DynamicModal
          open={bookingFlag}
          closable={true}
          close={() => setBookingFlag(false)}
          footer={null}
          width={1024}
          component={<BookingForm setBookingFlag={setBookingFlag} />}
        />
      )}
  </Layout>
  )
}
