import { Dropdown, Layout, Space, Button } from "antd";
import React, { useEffect, useState } from "react";
import "./Header.scss";
import studioLogo from "../../assets/images/studio-logo.webp";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

const { Header } = Layout;

const Showcase = () => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const items = [
    // {
    //   key: "0",
    //   label: (
    //     <NavLink to={`/gallery/0`} className="item-text">
    //       Jockey
    //     </NavLink>
    //   ),
    // },
    {
      key: "1",
      label: (
        <NavLink to={`/gallery/1`} className="item-text">
          Sister Point
        </NavLink>
      ),
    },
    {
      key: "2",
      label: (
        <NavLink to={`/gallery/3`} className="item-text">
          Smashed Lemon
        </NavLink>
      ),
    },
    {
      key: "3",
      label: (
        <NavLink to={`/gallery/4`} className="item-text">
          Knowledge Cotton
        </NavLink>
      ),
    },
    {
      key: "4",
      label: (
        <NavLink to={`/gallery/5`} className="item-text">
          Indochino
        </NavLink>
      ),
    },
  ];
  return (
    <Dropdown
      className="all-product-dropdown"
      placement="bottom"
      // trigger={["click"]}
      menu={{
        items,
      }}
      visible={openDropdown}
      onVisibleChange={(status) => setOpenDropdown(status)}
    >
      {openDropdown ? (
        <Space className="space" style={{ color: "#DE6B32" }}>
          Showcase
          <img
            src="https://gati-public.s3.ap-south-1.amazonaws.com/websiteAssets/assetsForLandingPage/assets/images/orange-arrow.svg"
            alt="arrow-icon"
          />
        </Space>
      ) : (
        <Space className="space">
          Showcase
          <img
            src="https://gati-public.s3.ap-south-1.amazonaws.com/websiteAssets/assetsForLandingPage/assets/images/downarrow-icon.svg"
            alt="arrow-icon"
          />
        </Space>
      )}
    </Dropdown>
  );
};

const Resources = () => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const items = [
    {
      key: "1",
      label: (
        <NavLink to={"/customer-story"} className="item-text">
          Customer Story
        </NavLink>
      ),
    },
    {
      key: "2",
      label: (
        <NavLink to="https://blogs.flixstock.com" className="item-text">
          Blog
        </NavLink>
      ),
    },
  ];
  return (
    <Dropdown
      className="all-product-dropdown"
      placement="bottom"
      // trigger={["click"]}
      menu={{
        items,
      }}
      visible={openDropdown}
      onVisibleChange={(status) => setOpenDropdown(status)}
    >
      {openDropdown ? (
        <Space className="space" style={{ color: "#DE6B32" }}>
          Resources
          <img
            src="https://gati-public.s3.ap-south-1.amazonaws.com/websiteAssets/assetsForLandingPage/assets/images/orange-arrow.svg"
            alt="arrow-icon"
          />
        </Space>
      ) : (
        <Space className="space">
          Resources
          <img
            src="https://gati-public.s3.ap-south-1.amazonaws.com/websiteAssets/assetsForLandingPage/assets/images/downarrow-icon.svg"
            alt="arrow-icon"
          />
        </Space>
      )}
    </Dropdown>
  );
};
const Company = () => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const navigate = useNavigate();
  const items = [
    {
      key: "1",
      label: (
        <div className="item-text" onClick={() => navigate("/about")}>
          About Us
        </div>
        //   <div className="item-text">
        //   About Us
        //  </div>
      ),
    },
    {
      key: "2",
      label: (
        <div className="item-text" onClick={() => navigate("/partner")}>
          Partner with us
        </div>
      ),
    },
  ];
  return (
    <Dropdown
      className="all-product-dropdown"
      placement="bottom"
      // trigger={["click"]}
      menu={{
        items,
      }}
      visible={openDropdown}
      onVisibleChange={(status) => setOpenDropdown(status)}
    >
      {openDropdown ? (
        <Space className="space" style={{ color: "#DE6B32" }}>
          Company
          <img
            src="https://gati-public.s3.ap-south-1.amazonaws.com/websiteAssets/assetsForLandingPage/assets/images/orange-arrow.svg"
            alt="arrow-icon"
          />
        </Space>
      ) : (
        <Space className="space">
          Company
          <img
            src="https://gati-public.s3.ap-south-1.amazonaws.com/websiteAssets/assetsForLandingPage/assets/images/downarrow-icon.svg"
            alt="arrow-icon"
          />
        </Space>
      )}
    </Dropdown>
  );
};

export const CustomHeader = ({ setBookingFlag }) => {
  const [active, setActive] = useState("1");
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleActive = (e) => {
    setActive(e.target.id);
  };

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  // useEffect(() => {
  // if(active === "0"){
  //   navigate("/home");
  // } else if(active === "1"){
  //   navigate("/studio");
  // } else {
  //   navigate("/imageedit");
  // }
  // },[active])

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Header className="header-section">
      <div className={`top-header non-mobile ${isSticky ? "sticky" : ""}`}>
        <div className="top-header-container">
          {/* <span
            className={active === "0" ? "active" : "top-header-text"}
            id={"0"}
            onClick={handleActive}
          >
            FlixStock
          </span> */}
          <NavLink
            to="https://promo.flixstock.com/"
            target="_self"
            className={active === "0" ? "active" : "top-header-text"}
            id={"0"}
            onClick={handleActive}
          >
            FlixStock
          </NavLink>

          <span style={{ color: "#a8a8a8" }}>|</span>
          <span
            className={active === "1" ? "active" : "top-header-text"}
            id={"1"}
            // onClick={handleActive}
            onClick={() => navigate("/studio")}
          >
            FlixStudio
          </span>
          <span style={{ color: "#a8a8a8" }}>|</span>
          {/* <span
            className={active === "2" ? "active" : "top-header-text"}
            id={"2"}
            onClick={handleActive}
          >
            ImageEdit.ai
           
          </span> */}
          <NavLink
            to="https://ai.imageedit.ai/"
            target="_self"
            className={active === "2" ? "active" : "top-header-text"}
            id={"2"}
            onClick={handleActive}
          >
            ImageEdit.ai
          </NavLink>
        </div>
      </div>
      <div className="bottom-header">
        <div className="bottom-header-container">
          <div
            className={`mobile-menu only-mobile ${
              showMobileMenu ? "overlay" : ""
            }`}
          >
            <div className="mobile-navbar">
              <div
                className="mobile-nav-item"
                onClick={() => {
                  setShowMobileMenu(false);
                  navigate("/about");
                }}
              >
                About Us
              </div>
              <div className="mobile-nav-item">
                <Button
                  className="primaryBtn Button"
                  onClick={() => {
                    setShowMobileMenu(false);
                    setBookingFlag(true);
                  }}
                >
                  Talk to an Expert
                </Button>
              </div>
              {/* <div className="mobile-nav-item">Teams</div>
              <div className="mobile-nav-item">Company</div> */}
            </div>
          </div>
          {/* {active === "0" ? (
            <div className="logo" >
              <img
                src="https://gati-public.s3.ap-south-1.amazonaws.com/websiteAssets/assetsForLandingPage/assets/images/logo.svg"
                alt="logo"
                width={60}
              />
            </div>
          ) : active === "1" ? (
            <div className="logo">
              <img src={studioLogo} alt="logo" className="studio-logo" />
            </div>
          ) : (
            ""
          )} */}
          <div
            className="logo"
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          >
            <img src={studioLogo} alt="logo" className="studio-logo" />
          </div>
          <div className="only-mobile mobile-menu-button-wrapper">
            <button
              className={`hamburger hamburger--spin ${
                showMobileMenu ? "is-active" : ""
              }`}
              type="button"
              onClick={toggleMobileMenu}
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </div>

          <div className="logo-right-side non-mobile">
            <div className="space" onClick={() => navigate("/studio")}>
              Studios
            </div>
            <Showcase />
            <Resources />
            <Company />
            {/* <Button
              className="primaryBtn Button"
              text="Talk to an expert"
              width="174px"
              onClick={showModal}
            />
            <TalkToExpertModal
              handleCancel={handleCancel}
              isModalOpen={isModalOpen}
            /> */}

            {!location.pathname.toLowerCase().includes("contact") && (
              <Button
                className="primaryBtn Button"
                onClick={() => setBookingFlag(true)}
              >
                Talk to an Expert
              </Button>
            )}
          </div>
        </div>
      </div>
    </Header>
  );
};
