import React, { lazy, useState } from 'react'
import "./ChatWithSales.scss";
import { CustomButton as Button } from '../../CommonComponents/button/Button';


const ChatWithSales = ({setBookingFlag}) => {
    
  return (
    <div className='chat-section'>
        <div className='chat-container'>
            <div className='left-side'>
                <img src="https://gati-public.s3.ap-south-1.amazonaws.com/websiteAssets/assetsForLandingPage/assets/images/CallUsImg.svg" alt='callUsImg'  />
            </div>
            <div className='right-side'>
                <div className='heading'>Still have query's </div>
                <div className='tik-container'>
                    <div className='tik-content' >
                        <img src="https://gati-public.s3.ap-south-1.amazonaws.com/websiteAssets/assetsForLandingPage/assets/images/tikIcon.svg" alt='tikIcon' />
                        <div className='text'>have a large project in mind?</div>
                    </div>
                    <div className='tik-content' >
                        <img src="https://gati-public.s3.ap-south-1.amazonaws.com/websiteAssets/assetsForLandingPage/assets/images/tikIcon.svg" alt='tikIcon' />
                        <div className='text'>need guidance building a custom shoot?</div>
                    </div>
                    <div className='tik-content' >
                        <img src="https://gati-public.s3.ap-south-1.amazonaws.com/websiteAssets/assetsForLandingPage/assets/images/tikIcon.svg" alt='tikIcon' />
                        <div className='text'>looking for brand specific model</div>
                    </div>
                </div>
                <Button text="Chat with sales" className="callBtn" width="212px" onClick={() => setBookingFlag(true)} />
               
            </div>
        </div>
      
    </div>
  )
}

export default ChatWithSales
