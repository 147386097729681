import React from "react";
import "./OurWorld.scss";
import AliceCarousel from "react-alice-carousel";
import { useNavigate } from "react-router-dom";

const OurWorld = () => {
  const navigate = useNavigate();

  const items = [
    <div className="sliderimg sliderimg1"  >
      <div className="learn-more-box" onClick={() => navigate("/studio/1")}>
        <div className="btn">New York,USA</div>
        <div className="learn">Learn More</div>
      </div>
    </div>,
    <div className="sliderimg sliderimg2"  >
    <div className="learn-more-box" onClick={() => navigate("/studio/3")}>
      <div className="btn">Italy, Italy</div>
      <div className="learn">Learn More</div>
    </div>
  </div>,
  <div className="sliderimg sliderimg3"  >
  <div className="learn-more-box" onClick={() => navigate("/studio/2")}>
    <div className="btn">Los Angeles, USA</div>
    <div className="learn">Learn More</div>
  </div>
</div>,
    <div className="sliderimg sliderimg4"  >
    <div className="learn-more-box" onClick={() => navigate("/studio/4")}>
      <div className="btn">Berlin, Germany</div>
      <div className="learn">Learn More</div>
    </div>
  </div>,
  <div className="sliderimg sliderimg5"  >
  <div className="learn-more-box" onClick={() => navigate("/studio/6")}>
    <div className="btn">New Delhi, India</div>
    <div className="learn">Learn More</div>
  </div>
</div>,
<div className="sliderimg sliderimg6"  >
      <div className="learn-more-box" onClick={() => navigate("/studio/5")}>
        <div className="btn">Bulgaria</div>
        <div className="learn">Learn More</div>
      </div>
    </div>,
    <div className="sliderimg sliderimg7"  >
    <div className="learn-more-box" onClick={() => navigate("/studio/0")}>
      <div className="btn">Poland</div>
      <div className="learn">Learn More</div>
    </div>
  </div>,
  ];
  return (
    <div className="our-world-section">
      <div className="our-world-container">
        <div className="heading">
          Our world wide studio network <br />
          has <span>delivered over 10M images</span>
        </div>
        <div className="carousel-section">
          <AliceCarousel
            items={items}
            mouseTracking="true"
            controlsStrategy="alternate"
            disableButtonsControls
            disableDotsControls
            infinite={true}
            autoPlay={true}
            autoPlayInterval={800}
          />
        </div>
      </div>
    </div>
  );
};

export default OurWorld;
