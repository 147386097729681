import { Divider} from "antd";
import React, { useState } from "react";
import "./Footer.scss";
import logo from "../../assets/images/studio-logo.webp"
import linkedIn from "../../assets/images/Linkedin (1).webp"
import youtube from "../../assets/images/Youtube.webp"
import instagram from "../../assets/images/Instagram.webp"
import facebook from "../../assets/images/Facebook (1).webp"
import { useNavigate } from "react-router-dom";

export const CustomFooter = ({setBookingFlag}) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  // const handleSubscribe =() =>{
  //   let pattern = /^(?!.*(?:''|\.\.))[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  //   let finalObj={
  //     "email":email,
  //     identifier:'FlixStudio'
  //   }
  //   if(pattern.test(email)){
  //     fetch("https://api-pegasus.flixstudio.io/limited/api/v1/inquiry/newSubscriptionAlert", {
  //       // Return promise
  //       method: "POST",
  //       // withCredentials: true,
  //       // credentials: 'include',
  //       body: JSON.stringify(finalObj),
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     })
  //       .then((res) => {
  //         if (res.status >= 200 && res.status < 300) {
  //           notification.success({
  //             message:"Request Successful",
  //           })
  //         }
  //       })
  //       .then(
  //         (result) => {},
  //         (error) => {
  //           console.log(error);
  //         }
  //       );
  //   }
  //   else{
  //     notification.info({
  //       message:"Error",
  //       description:"Please enter a valid email address."
  //     })
  //   }
  // }


  return (
    <>
      <footer className="non-mobile">
      <div className="footer-section">
        <div className="footer-container">
          <div className="footer-top-section">
            <div className="footer-top-left">
              <div className="footer-logo">
                <img src={logo} alt="" />
              </div>
              <div className="logo-text">
                Selling omnichannel offers increased{" "}
                <span>visibility and access to multiple demographics,</span>{" "}
                providing a competitive advantage through diversification.
              </div>
            </div>
            <div className="footer-top-right">
              <div className="footer-top-right-up">
                <div className="footer-top-up">
                  <div className="footer-top-up-heading">
                    info@flixstudio.io
                  </div>
                  <div className="footer-top-up-heading-text">Company</div>
                  <div className="footer-top-up-text" onClick={() => navigate("/about")}>About Us</div>
                  <div className="footer-top-up-text" onClick={() => navigate("/partner")}>Partner with us</div>
                  <div className="footer-top-up-text" onClick={() => setBookingFlag(true)}>Pricing</div>
                </div>
                <div className="footer-top-up">
                  <div className="footer-top-up-heading">(917) 830-6172</div>
                  <div className="footer-top-up-heading-text">Resources</div>
                  <div className="footer-top-up-text">
                    <a href="https://blogs.flixstock.com/">Blog</a>
                  </div>

                  {/* <div className="footer-top-up-text">News & Events</div> */}

                  <div className="footer-top-up-text" onClick={() => navigate("/customer-story")}>Customer Story</div>
                </div>
              </div>
              <div className="footer-top-right-bottom">
                <form className="form">
                  <label>Subscribe to our emails </label>
                  <br />
                  <input placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)}  />

                  <button type="submit">Subscribe</button>
                </form>
              </div>
            </div>
          </div>
          <Divider orientation="left" orientationMargin="0" className="divider">
            <span>USA</span>
            <span>EUROPE</span>
            <span>GERMANY</span>
          </Divider>
          <div className="footer-middle-section">
            <div className="content">
              E-commerce Product Photography, New York <br />Flat Lay Product
              Photography, New York <br />Ghost Mannequin Product Photography, New
              York <br />Clothing Product Photography, New York
            </div>
            <div className="content">
              E-commerce Product Photography, Los Angeles <br />Flat Lay Product
              Photography, Los Angeles <br />Ghost Mannequin Product Photography, LOS
              Angeles <br />Clothing Product Photography, Los Angeles
            </div>
            <div className="content">
              E-commerce Product Photography, San Francisco <br />Flat Lay Product
              Photography, San Francisco <br />Ghost Mannequin Product Photography,
              San Francisco <br />Clothing Product Photography, San Francisco
            </div>
          </div>
          <div className="link-icon-section">
          <img src={linkedIn} alt="linkedin" />
          <img src={youtube} alt="youtube" />
          <img src={instagram} alt="instagram" />
          <img src={facebook} alt="facebook" />
          </div>
          <Divider style={{ background: "#b5b5b5" }} className="second-divider" />
          <div className="footer-bottom-section">
            <div className="bootom-left">
              {/* <div>Newsletter</div> */}
              <a href="https://en.flixstock.com/career/">Career</a>
              {/* <div>FAQ</div> */}
              <div onClick={() => navigate("/terms")}>Terms & Condition</div>
              <div onClick={() => navigate("/policy")}>Privacy Policy</div>
            </div>
            <div className="bootom-right">
              © FlixStudio 2023 | All rights reserved
            </div>
          </div>
        </div>
      </div>
     </footer>
     <footer className="only-mobile">
      <div className="footer-mobile-section">
        <div className="footer-container">
          <div className="footer-top-section">
            <div className="footer-top-left">
              <div className="footer-logo">
                <img src={logo} alt="" />
              </div>
              <div className="logo-text">
                Selling omnichannel offers increased{" "}
                <span>visibility and access to multiple demographics,</span>{" "}
                providing a competitive advantage through diversification.
              </div>
            </div>
            <div className="footer-top-right">
              <div className="footer-top-right-up">
                <div className="footer-top-up">
                  <div className="footer-top-up-heading">
                    info@flixstudio.io
                  </div>
                  <div className="footer-top-up-heading-text">Company</div>
                  <div className="footer-top-up-text">About Us</div>
                  <div className="footer-top-up-text">Partner with us</div>
                  <div className="footer-top-up-text">Pricing</div>
                </div>
                <div className="footer-top-up">
                  <div className="footer-top-up-heading">(917) 830-6172</div>
                  <div className="footer-top-up-heading-text">Resources</div>
                  <a className="footer-top-up-text" href="https://blogs.flixstock.com/">Blog</a>

                  <div className="footer-top-up-text">News & Events</div>

                  <div className="footer-top-up-text">Customer Story</div>
                </div>
              </div>
              <div className="footer-top-right-bottom">
                <form className="form">
                  <label>Subscribe to our emails </label>
                  <br />
                  <input placeholder="Enter your email" />

                  <button>Subscribe</button>
                </form>
              </div>
            </div>
          </div>
          
          <div className="footer-middle-section">
            <div className="content">
              E-commerce Product Photography, New York <br />Flat Lay Product
              Photography, New York <br />Ghost Mannequin Product Photography, New
              York <br />Clothing Product Photography, New York
            </div>
            <div className="content">
              E-commerce Product Photography, Los Angeles <br />Flat Lay Product
              Photography, Los Angeles <br />Ghost Mannequin Product Photography, LOS
              Angeles <br />Clothing Product Photography, Los Angeles
            </div>
            <div className="content">
              E-commerce Product Photography, San Francisco <br />Flat Lay Product
              Photography, San Francisco <br />Ghost Mannequin Product Photography,
              San Francisco <br />Clothing Product Photography, San Francisco
            </div>
          </div>
          
          <Divider style={{ background: "#b5b5b5" }} className="second-divider" />
          <div className="footer-bottom-section">
            <div className="bootom-left">
             <div>
             
              <a href="https://en.flixstock.com/career/">Career</a>
             </div>
              <div>
              <span onClick={() => navigate("/terms")}>Terms & Condition</span>
              <span onClick={() => navigate("/policy")}>Privacy Policy</span>
              </div>
            </div>
            <div className="bootom-right">
              © FlixStock 2023 | All rights reserved
            </div>
            <div className="link-icon-section">
          <img src={linkedIn} alt="linkedin" />
          <img src={youtube} alt="youtube" />
          <img src={instagram} alt="instagram" />
          <img src={facebook} alt="facebook" />
          </div>
          </div>
        </div>
      </div>
     </footer>
    </>
  );
};
