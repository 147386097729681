import { Button, Col, Row } from "antd";
import React from "react";
import "./ReduceReturn.scss";

const ReduceReturn = ({setBookingFlag}) => {
  return (
    <div className="reduce-return-section">
      <div className="reduce-return-container">
        <div className="heading">
          Reduce returns with 99%
          <br />
          true color representation
        </div>
        <div className="sub-heading non-mobile">
          At our core, we deliver a remarkable 99% true color representation,
          Trust us to match your <br />
          product's hues perfectly, ensuring you don’t get any returns due to
          color mismatch
        </div>
        <div className="sub-heading only-mobile">
          At our core, we deliver a remarkable 99% true color representation,
          Trust us to match your 
          product's hues perfectly, ensuring you don’t get any returns due to
          color mismatch
        </div>
        <Row gutter={[16, 16]} className="non-mobile">
          <Col span={12}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <div className="col col1">
                  <div className="items">
                    World class
                    <br />
                    high end studio lighting <br />
                    setup to capture true colour
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div className="col col2">
                  <div className="items">
                    Use of Checker box and ICC <br />
                    color Profile for perfect color and <br />
                    skin tone
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <div className="col col3">
                  <div className="items">
                    Use of grey card to <br />
                    remove color cast
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div className="col col4">
                  <div className="items">
                    Pure day light -environment <br />
                    setup at every studio to match <br />
                    color with physical samples
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="only-mobile">
        <Col span={24}>
                <div className="col col1">
                  <div className="items">
                    World class
                    <br />
                    high end studio lighting <br />
                    setup to capture true colour
                  </div>
                </div>
              </Col>
              
              <Col span={24}>
                <div className="col col3">
                  <div className="items">
                    Use of grey card to <br />
                    remove color cast
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div className="col col2">
                  <div className="items">
                    Use of Checker box and ICC <br />
                    color Profile for perfect color and <br />
                    skin tone
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div className="col col4">
                  <div className="items">
                    Pure day light -environment <br />
                    setup at every studio to match <br />
                    color with physical samples
                  </div>
                </div>
              </Col>
        </Row>
        <div >
       <Button className="btn non-mobile" onClick={() => setBookingFlag(true)}> Read how true colour representation can reduce your return rate</Button>
       <Button className="btn only-mobile" onClick={() => setBookingFlag(true)}> Read how true colour representation <br />can reduce your return rate</Button>

        </div>
      </div>
    </div>
  );
};

export default ReduceReturn;
