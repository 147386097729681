import React, { useEffect, useRef } from "react";
import { Col, Row } from "antd";
import "./ExploreNow.scss";
import { CustomArrowButton as Button } from "../../CommonComponents/button/Button";
import img1 from "../../assets/images/explore1.webp";
import img2 from "../../assets/images/explore2.webp";
import img3 from "../../assets/images/explore3.webp";
import img4 from "../../assets/images/explore5.webp";
import img5 from "../../assets/images/explore6.png";
import ReactPlayer from "react-player";
import video from "../../assets/videos/Live check.mp4";

const data = [
  {
    id: 0,
    title: (
      <div className="heading">
        One step for model <br />
        selection & booking
      </div>
    ),
    text: (
      <div className="sub-heading">
        Streamlining the model selection from worldwide
        <br /> best modeling agencies and booking process <br />
        into a single step.
      </div>
    ),
    img: img1,
  },
  {
    id: 1,
    title: (
      <div className="heading">
        Tracking project progress <br />
        and notifications
      </div>
    ),
    text: (
      <div className="sub-heading">
        Receive real-time shoot updates via a <br />
        dependable notification system, eliminating the <br />
        need for frequent calls to the account manager.
      </div>
    ),
    img: img2,
  },
  {
    id: 2,
    title: (
      <div className="heading">
        Build the Perfect <br />
        Style Guide
      </div>
    ),
    text: (
      <div className="sub-heading">
        We acknowledge that images are subjective, <br />
        with unique requirements. We use a guideline <br />
        system to capture client preferences, shoot <br />
        guidelines, and styling needs
      </div>
    ),
    img: img3,
  },
  {
    id: 3,
    title: (
      <div className="heading">
        One platform to review <br />
        images, share feedback <br />
        and download images
      </div>
    ),
    text: (
      <div className="sub-heading">
        Implementing an image marking system for <br />
        precise feedback, leading to a shorter feedback <br />
        loop and improved efficiency.
      </div>
    ),
    img: img4,
  },
];
const ExploreNow = ({ setBookingFlag }) => {

  const videoPlayerRef = useRef(null);


  return (
    <>
      <div className="explore-section non-mobile">
        <Row gutter={[32, 32]}>
          <Col span={24}>
            <div className="horizentol">
              <div className="left-side">
                <div className="heading">
                  One step for model <br />
                  selection & booking
                </div>
                <div className="sub-heading">
                  Streamlining the model selection from <br />
                  worldwide best modeling agencies and <br />
                  booking process into a single step.
                </div>
                <Button
                  title="Explore now"
                  className="explore-btn"
                  setBookingFlag={setBookingFlag}
                />
              </div>
              <div className="right-side">
                <img src={img1} alt="img" className="img1" />
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="verticle">
              <div className="top-side">
                <img src={img2} alt="img" />
              </div>
              <div className="bottom-side">
                <div className="heading">
                  Tracking project progress <br />
                  and notifications
                </div>
                <div className="sub-heading">
                  Receive real-time shoot updates via a <br />
                  dependable notification system, eliminating the <br />
                  need for frequent calls to the account manager.
                </div>
                <Button
                  title="Explore now"
                  className="explore-btn"
                  setBookingFlag={setBookingFlag}
                />
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="verticle">
              <div className="top-side">
                <img src={img3} alt="img" />
              </div>
              <div className="bottom-side">
                <div className="heading">
                  Build the Perfect <br />
                  Style Guide
                </div>
                <div className="sub-heading">
                  We acknowledge that images are subjective, with <br />
                  unique requirements. We use a guideline system to <br />
                  capture client preferences, shoot guidelines, and <br />
                  styling needs
                </div>
                <Button
                  title="Explore now"
                  className="explore-btn"
                  setBookingFlag={setBookingFlag}
                />
              </div>
            </div>
          </Col>
          <Col span={24}>
            <div className="horizentol">
              <div className="left-side">
                <div className="heading">
                  One platform to review <br />
                  images, share feedback <br />
                  and download images
                </div>
                <div className="sub-heading">
                  Implementing an image marking system <br />
                  for precise feedback, leading to a shorter <br />
                  feedback loop and improved efficiency.
                </div>
                <Button
                  title="Explore now"
                  className="explore-btn"
                  setBookingFlag={setBookingFlag}
                />
              </div>
              <div className="right-side">
                <img src={img4} alt="img4" className="img4" />
              </div>
            </div>
          </Col>
          <Col span={24}>
            <div className="horizentol">
              <div className="left-side-video">
                <ReactPlayer
                  ref={(player) => (videoPlayerRef.current = player)}
                  url={video}
                  loop={true}
                  width={"100%"}
                  height={"100%"}
                  playing={true}
                  muted={true}
                  controls={false}
                  className="video"
                />
              </div>
              <div className="left-side">
                <div className="heading">
                  Witness Your Garment <br />
                  Shoot Live and Share <br />
                  Feedback in Real Time!
                </div>
                <div className="sub-heading">
                  Every photo session commences with an initial <br />
                  kickoff call. Witness content materialising in real-
                  <br />
                  time and provide feedback seamlessly through <br />
                  our live chat!
                </div>
                <Button
                  title="Explore now"
                  className="explore-btn"
                  setBookingFlag={setBookingFlag}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="explore-section only-mobile">
        <Row gutter={[0, 16]}>
          {data.map((item, index) => (
            <Col span={24}>
              <div className="verticle">
                <div className="bottom-side">
                  {item.title}
                  {item.text}
                  <Button
                    title="Explore now"
                    className="explore-btn"
                    setBookingFlag={setBookingFlag}
                  />
                </div>
                <div className="top-side">
                  <img src={item.img} alt="img" />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default ExploreNow;
