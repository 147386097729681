import React from "react";
import "./AboutOurClient.scss";
import { Button, Col, Row } from "antd";
import img1 from "../../assets/images/customer-1-client-1.webp";
import img2 from "../../assets/images/customer-1-client-2.webp";
import img3 from "../../assets/images/customer-1-client-3.webp";
import img4 from "../../assets/images/customer-2-client.webp"

const AboutOurClient = ({id, setBookingFlag}) => {
  return (
    <>
      {id == 1 ? (
        <div className="client-section ">
          <div className="client-container">
            <div className="left-side">
              <div className="left-side-img">
                <img src={img1} alt="img" />
              </div>
              <div className="right-side-img">
                <div className="top-img">
                  <img src={img2} alt="img" />
                </div>
                <div className="bottom-img">
                  <img src={img3} alt="img" />
                </div>
              </div>
            </div>
            <div className="right-side">
              <div className="heading">About Our Client</div>
              <div className="text">
                With 832 stores and more than 14,000 active professionals
                worldwide, the brand is successfully leading the world of
                sportswear. As of 2017, this multinational corporation designs,
                manufactures, and distributes its performance and
                sports-inspired lifestyle products in more than 120 countries.
              </div>
              <div className="sub-title">
                “Our client leads the sportswear industry, which is why <br />
                we can’t divulge its name. Please feel free to <br />
                contact us for further proof.”
              </div>
              <Button className="primaryBtn" onClick={() => setBookingFlag(true)}>Get in touch</Button>
            </div>
          </div>
        </div>
      ) : id == 2 ? (
        <div className="client-section customer-2-client">
          <div className="client-container">
            <div className="right-side">
              <div className="heading">About Our Client</div>
              <div className="text">
                With 832 stores and more than 14,000 active professionals
                worldwide, the brand is successfully leading the world of
                sportswear. As of 2017, this multinational corporation designs,
                manufactures, and distributes its performance and
                sports-inspired lifestyle products in more than 120 countries.
              </div>
              <div className="sub-title">
                “Our client leads the sportswear industry, which is why <br />
                we can’t divulge its name. Please feel free to <br />
                contact us for further proof.”
              </div>
              <Button className="primaryBtn" onClick={() => setBookingFlag(true)}>Get in touch</Button>
            </div>
            <div className="left-side">
              <div className="img">
                <img src={img4} alt="img" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default AboutOurClient;
