import React from 'react'
import { Content } from 'antd/es/layout/layout'
import Helmet from '../../CommonComponents/helmet/Helmet'
import Elevate from "../../Components/elevate/Elevate";

import Fashion from '../../Components/fashion/Fashion';
import BetterChoice from '../../Components/betterChoice/BetterChoice';
import FullControl from '../../Components/fullControl/FullControl';
import LookCreator from '../../Components/lookCreator/LookCreator';
import KnowHowItWorks from '../../Components/knowHowItWorks/KnowHowItWorks';
import AccountManager from '../../Components/accountManager/AccountManager';
import SelectingModel from '../../Components/selectingModel/SelectingModel';
import Hqi from '../../Components/hqi/Hqi';
import ReduceReturn from '../../Components/reduceReturn/ReduceReturn';
import OurWorld from '../../Components/ourWorld/OurWorld';
import TalkToSpecialist from '../../Components/talkToSpecialist/TalkToSpecialist';
import SatisfiedCustomer from '../../Components/satsfiedCustomer/SatisfiedCustomer';
import Offers from '../../Components/offers/Offers';
import HaveQuestions from '../../Components/haveQuestions/HaveQuestions';
import ChatWithSales from '../../Components/chatWithSales/ChatWithSales';


const Studio = ({setBookingFlag}) => {
  // dummy comment
  return (
    <Helmet title='FlixStudio'>
     <Content style={{background: "#FFFFFF"}}>
   
     <Elevate setBookingFlag={setBookingFlag}  />

      
      <Fashion />
      <BetterChoice setBookingFlag={setBookingFlag} />
      <SelectingModel  />
      <FullControl setBookingFlag={setBookingFlag} />
      <LookCreator />
      <ReduceReturn setBookingFlag={setBookingFlag} />
      <Hqi />
      <KnowHowItWorks />
      <AccountManager />
      <TalkToSpecialist setBookingFlag={setBookingFlag} />
      <OurWorld />
      <SatisfiedCustomer />
      <Offers setBookingFlag={setBookingFlag} />
      <HaveQuestions setBookingFlag={setBookingFlag} />
      <ChatWithSales setBookingFlag={setBookingFlag} />
     </Content>
     </Helmet>
  
  )
}

export default Studio
