import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import React, { useState } from "react";
import "./style.scss";
import codes from "country-calling-code";
import CountryFlagSvg from "country-list-with-dial-code-and-flag/dist/flag-svg";
import { useLocation } from "react-router-dom";
import ThankyouPage from "./ThankYouPage";
import modelGif from "../../assets/images/ModelGif.gif";
const { Title } = Typography;
const BookingForm = (props) => {
  // const modelGif =
  //   "https://gati-public.s3.ap-south-1.amazonaws.com/websiteAssets/assetsForLandingPage/assets/images/Model Gif 1.svg";
  const { Option } = Select;
  const [formDetails, setFormDetails] = useState({
    name: "",
    email: "",
    number: "",
    organization: "",
  });
  const [countryCode, setCountryCode] = useState("US");
  const [submitFlag, setSubmitFlag] = useState(false);
  const [redirected, setRedirected] = useState(false);
  const [errors, setErrors] = useState({});
  const location = useLocation();

  const prefixSelector = (
    <Select
      defaultValue="US"
      className="country_select"
      showSearch
      optionFilterProp="children"
      onChange={(e) => setCountryCode(e)}
      filterOption={(input, lab) =>
        lab?.children
          ? (
              lab?.children[lab?.children?.length - 2] +
              lab?.children[lab?.children?.length - 1]
            )?.includes(input) ||
            lab.value.toLocaleLowerCase().includes(input.toLocaleLowerCase())
          : ""
      }
    >
      {codes.map((elm) => (
        <Option key={elm.isoCode2} className="flag_option" value={elm.isoCode2}>
          <img
            alt=""
            width={20}
            height={20}
            src={`data:image/svg+xml;utf8,${encodeURIComponent(
              CountryFlagSvg[elm.isoCode2]
            )}`}
          />{" "}
          +{elm.countryCodes[0]}
        </Option>
      ))}
    </Select>
  );

  const handleInputs = (e, type) => {
    if (errors.hasOwnProperty(e?.target?.name ?? type)) {
      let newObj = { ...errors };
      delete newObj[e?.target?.name ?? type];
      setErrors(newObj);
    }
    setFormDetails({
      ...formDetails,
      [e?.target?.name ?? type]: e?.target?.value ?? e,
    });
  };
  const handleValidations = () => {
    let tempErr = {};
    let pattern =
      /^(?!.*(?:''|\.\.))[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    // for(let key in formDetails){
    //     if( !['number','organization'].includes(key) && formDetails[key] == ''){
    //         tempErr[key] = 'This field can not be empty'
    //     }
    // }
    if (formDetails.name == "") {
      tempErr["name"] = "Please enter your name";
    }

    if (
      formDetails.number != "" &&
      !(formDetails.number.toString().length == 10)
    ) {
      tempErr["number"] = "Enter a valid 10 digit number";
    }
    if (
      (formDetails.email != "" && !pattern.test(formDetails.email)) ||
      formDetails.email == ""
    ) {
      tempErr["email"] = "Enter a valid email address";
    }
    setErrors(tempErr);
    if (Object.keys(tempErr).length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = async (type) => {
    const result = await handleValidations();
    console.log(result);
    let tempObj = { ...formDetails };
    let prefixCode = codes.find((el) => el.isoCode2 == countryCode);
    let finalObj = {
      ...tempObj,
      number:
        tempObj.number == ""
          ? "--"
          : "+" + prefixCode.countryCodes + tempObj.number.toString(),
      organization: tempObj.organization == "" ? "--" : tempObj.organization,
      identifier: "FlixStudio",
    };
    if (result) {
      fetch("https://api-pegasus.flixstudio.io/limited/api/v1/inquiry", {
        // Return promise
        method: "POST",
        // withCredentials: true,
        // credentials: 'include',
        body: JSON.stringify(finalObj),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            if (type == "booking") redirectToCalendly();
            else setSubmitFlag(true);
          }
        })
        .then(
          (result) => {},
          (error) => {
            console.log(error);
          }
        );
    }
  };
  const redirectToCalendly = (type) => {
    var a = document.createElement("a");
    a.target = "_blank";
    a.href = "https://calendly.com/madhur-khurana/30min?month=2023-08";
    a.click();

    if (type != "callback") {
      setSubmitFlag(true);
      setRedirected(true);
    } else props.setBookingFlag(false);
  };

console.log(formDetails);
  return (
    <div
      className="modal_inner_content"
      style={{
        width: location.pathname.toLowerCase().includes("contact")
          ? "99%"
          : "100%",
      }}
    >
      {!props.hideGif && (
         <div className="modal-gif">
        <img
          src={modelGif}
          loading="eager"
          className={
            location.pathname.toLowerCase().includes("contact")
              ? "model_img_form resp_height"
              : "model_img_form"
          }
          alt=""
        />
      </div>
      )}

      {!submitFlag ? (
        <Row
          gutter={[
            16,
            { xs: props.hideGif ? 5 : 12, md: props.hideGif ? 10 : 26 },
          ]}
          className="form"
          style={{
            marginTop:
              location.pathname.toLowerCase().includes("contact") && "85px",
          }}
        >
          {!props.hideGif && (
            <Col
              span={24}
              className={
                location.pathname.toLowerCase().includes("contact")
                  ? "text-center"
                  : "text-center"
              }
            >
              <Space
                direction="vertical"
                align={
                  location.pathname.toLowerCase().includes("contact")
                    ? "start"
                    : "center"
                }
              >
                <Title
                  level={
                    location.pathname.toLowerCase().includes("contact") ? 2 : 3
                  }
                  className="form_title"
                >
                  Talk To An Expert
                </Title>
                <span className="form_description">
                  Discover how FlixStock can enhance your customer's <br />
                  image experience throughout their buying journey.
                </span>
              </Space>
            </Col>
          )}

          <Col xs={{ span: 24 }} md={{ span: props.hideGif ? 24 : 12 }}>
            <Space.Compact direction="vertical" className="input_space">
              <label className="form_label">
                Name<span>*</span>
              </label>
              <Input
                className="form_inputs"
                placeholder="Enter name"
                name="name"
                onChange={(e) => handleInputs(e)}
              />
              {errors.name && (
                <span className="error_class">{errors.name}</span>
              )}
            </Space.Compact>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: props.hideGif ? 24 : 12 }}>
            <Space.Compact direction="vertical" className="input_space">
              <label className="form_label">Contact Number</label>
              <InputNumber
                className="form_inputs"
                placeholder="Enter contact number"
                controls={false}
                addonBefore={prefixSelector}
                min={0}
                name="number"
                onChange={(e) => handleInputs(e, "number")}
              />
              {/* <Input className='form_inputs' placeholder='enter name' addonBefore={prefixSelector} name='number' onChange={(e)=>handleInputs(e)} /> */}
              {errors.number && (
                <span className="error_class">{errors.number}</span>
              )}
            </Space.Compact>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: props.hideGif ? 24 : 12 }}>
            <Space.Compact direction="vertical" className="input_space">
              <label className="form_label">
                Bussines Email<span>*</span>
              </label>
              <Input
                className="form_inputs"
                placeholder="Enter email"
                name="email"
                onChange={(e) => handleInputs(e)}
              />
              {errors.email && (
                <span className="error_class">{errors.email}</span>
              )}
            </Space.Compact>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: props.hideGif ? 24 : 12 }}>
            <Space.Compact direction="vertical" className="input_space">
              <label className="form_label">Organization</label>
              <Input
                className="form_inputs"
                placeholder="Enter organization"
                name="organization"
                onChange={(e) => handleInputs(e)}
              />
              {errors.organization && (
                <span className="error_class">{errors.organization}</span>
              )}
            </Space.Compact>
          </Col>

          <Col
            xs={{ span: 24 }}
            md={{
              span: props.hideGif
                ? 24
                : location.pathname.toLowerCase().includes("contact")
                ? 8
                : 12,
            }}
          >
            <Button
              className="form-btns form-btn-1"
              onClick={() => handleSubmit("booking")}
            >
              Book a meeting
            </Button>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{
              span: props.hideGif
                ? 24
                : location.pathname.toLowerCase().includes("contact")
                ? 8
                : 12,
            }}
          >
            <Button
              className="form-btns form-btn-2"
              onClick={() => handleSubmit("callback")}
            >
              Request a callback
            </Button>
          </Col>
          <Col span={24} className="dismiss-btn">
            {!props.hideGif &&
              !location.pathname.toLowerCase().includes("contact") && (
                <Button
                  type="ghost"
                  style={{ color: "#a0a3bd" }}
                  onClick={() => props.setBookingFlag(false)}
                >
                  Dismiss
                </Button>
              )}
          </Col>
        </Row>
      ) : (
        <ThankyouPage
          closeModal={() => props.setBookingFlag(false)}
          redirected={redirected}
          redirectToCalendly={redirectToCalendly}
        />
      )}
    </div>
  );
};

export default BookingForm;
