import React from 'react'
import "./Brands.scss";
import img1 from "../../assets/images/brand1.webp";
import img2 from "../../assets/images/brand2.webp";
import img3 from "../../assets/images/brand3.webp";
// import img4 from "../../assets/images/brand4.webp";
import img5 from "../../assets/images/brand5.webp";

import img6 from "../../assets/images/brand6.webp";
import img7 from "../../assets/images/brand7.webp";
import img8 from "../../assets/images/brand8.webp";
import img9 from "../../assets/images/brand9.webp";
import img10 from "../../assets/images/brand10.webp";
import img11 from "../../assets/images/brand11.webp";
import img12 from "../../assets/images/brand12.webp";
import img13 from "../../assets/images/brand13.webp";
import img14 from "../../assets/images/brand14.webp";
import img15 from "../../assets/images/brand15.webp";
import img16 from "../../assets/images/brand16.webp";
// import img17 from "../../assets/images/brand17.webp";
import img18 from "../../assets/images/brand18.webp";
import img19 from "../../assets/images/brand19.webp";
import img20 from "../../assets/images/brand20.webp";




const Brands = () => {
  return (
    <>
    

    <div id="container" className='non-mobile'>
  <div class="photobanner">
  <img src={img1} alt="" />
  <img src={img2} alt="" />
  <img src={img3} alt="" />
  {/* <img src={img4} alt="" /> */}
  {/* <img src={img5} alt="" /> */}
  <img src={img6} alt="" />
  <img src={img7} alt="" />
  <img src={img8} alt="" />
  <img src={img9} alt="" />
  <img src={img10} alt="" />
  <img src={img11} alt="" />
  <img src={img12} alt="" />
  <img src={img13} alt="" />
  <img src={img14} alt="" />
  <img src={img15} alt="" />
  <img src={img16} alt="" />
  {/* <img src={img17} alt="" /> */}
  <img src={img18} alt="" />
  <img src={img19} alt="" />
  <img src={img20} alt="" />
  </div>
</div>
    </>
  )
}

export default Brands
