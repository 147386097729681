import React from "react";
import "./Fashion.scss";
import img1 from "../../assets/images/united-states.webp";
import img2 from "../../assets/images/denmark.webp";
import img3 from "../../assets/images/denmark-2.webp";
import img4 from "../../assets/images/netherlands.webp";
import img5 from "../../assets/images/denmark-3.webp";
import img6 from "../../assets/images/vancouver.webp";
import galleryImg from "../../assets/images/gallery-text-img.webp";
import galleryMobileImg from "../../assets/images/gallery-text-img-mobile.webp";
import arrow1 from "../../assets/images/fashion-arrow.webp";
import arrow2 from "../../assets/images/fashion-orange-arrow.webp";

import { Button, Col, Row } from "antd";
import Brands from "../brands/Brands";
import { useState } from "react";
import { NavLink } from "react-router-dom";

const Fashion = () => {
  const [isHoveredFirst, setIsHoveredFirst] = useState(false);
  const [isHoveredSecond, setIsHoveredSecond] = useState(false);
  const [isHoveredThird, setIsHoveredThird] = useState(false);
  const [isHoveredFourth, setIsHoveredFourth] = useState(false);
  const [isHoveredFifth, setIsHoveredFifth] = useState(false);
  const [isHoveredSixth, setIsHoveredSixth] = useState(false);

  const handleHoverFirst = () => {
    setIsHoveredFirst(!isHoveredFirst);
  };

  const handleHoverSecond = () => {
    setIsHoveredSecond(!isHoveredSecond);
  };

  const handleHoverThird = () => {
    setIsHoveredThird(!isHoveredThird);
  };

  const handleHoverFourth = () => {
    setIsHoveredFourth(!isHoveredFourth);
  };

  const handleHoverFifth = () => {
    setIsHoveredFifth(!isHoveredFifth);
  };

  const handleHoverSixth = () => {
    setIsHoveredSixth(!isHoveredSixth);
  };

  const data = [
    {
      id: 0,
      bgImg: img1,
      subTitle: "// UNITED STATES",
      title: "Target",
      isHovered: isHoveredFirst,
      handleHover: handleHoverFirst,
    },
    {
      id: 1,
      bgImg: img2,
      subTitle: "// DENMARK",
      title: "Sister’s Point",
      isHovered: isHoveredSecond,
      handleHover: handleHoverSecond,
    },
    {
      id: 2,
      bgImg: img3,
      subTitle: "// DENMARK",
      title: "BTX Group",
      isHovered: isHoveredThird,
      handleHover: handleHoverThird,
    },
    {
      id: 3,
      bgImg: img4,
      subTitle: "// NETHERLANDS",
      title: "Smashed Lemon",
      isHovered: isHoveredFourth,
      handleHover: handleHoverFourth,
    },
    {
      id: 4,
      bgImg: img5,
      subTitle: "// DENMARK",
      title: "Knowledge Cotton",
      isHovered: isHoveredFifth,
      handleHover: handleHoverFifth,
    },

    {
      id: 5,
      bgImg: img6,
      subTitle: "// VANCOUVER",
      title: "Indochino",
      isHovered: isHoveredSixth,
      handleHover: handleHoverSixth,
    },
  ];
  return (
    <div className="fashion-section">
      <div className="fashion-container non-mobile">
        <div className="product-card-container">
          <img
            src={galleryImg}
            alt="galleryImg"
            className="galleryImg-vertical"
          />
          <img
            src={galleryMobileImg}
            alt="galleryImg"
            className="galleryImg-horizental"
          />
          <div className="product-card-content">
            <Row gutter={[16, 16]}>
              {data.map((item) => (
                <Col span={12}>
                  <div
                    className="container"
                    style={{ background: `url(${item.bgImg})` }}
                  >
                    <div className="content">
                      <div className="left-side">
                        <div className="sub-title">{item.subTitle}</div>
                        <div className="title">{item.title}</div>
                      </div>
                      <div className="right-side">
                        {/* <img src={arrow} alt="arrow" /> */}
                        <NavLink to={`/gallery/${item.id}`}>
                          <Button
                            onMouseEnter={item.handleHover}
                            onMouseLeave={item.handleHover}
                            className="arrow-btn"
                          >
                            <img src={item.isHovered ? arrow2 : arrow1} />
                          </Button>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
            <span class="gallery-text non-mobile">
              "Fashion brands from across the world uses us for their e-commerce
              images"
            </span>
          </div>
        </div>
      </div>

      <div className="fashion-container only-mobile">
        <img src={galleryMobileImg} alt="galleryImg" className="galleryImg" />
        <div className="product-card-content">
          <Row>
            {data.map((item) => (
              <Col span={24}>
                <div
                  className="container"
                  style={{ background: `url(${item.bgImg})` }}
                >
                  <div className="content">
                    <div className="left-side">
                      <div className="sub-title">{item.subTitle}</div>
                      <div className="title">{item.title}</div>
                    </div>
                    <div className="right-side">
                      {/* <img src={arrow} alt="arrow" /> */}
                      <Button
                        onMouseEnter={item.handleHover}
                        onMouseLeave={item.handleHover}
                        className="arrow-btn"
                      >
                        <img src={item.isHovered ? arrow2 : arrow1} />
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <span class="gallery-text">
            "Fashion brands from across the world uses us for their e-commerce
            images"
          </span>
        </div>
      </div>
      <div className="brand-container">
        <Brands />
      </div>
    </div>
  );
};

export default Fashion;
